import { Box, Button, Chip, Container, FormControl, Grid, Paper, Stack, Tab, Tabs, TextField, Typography, useMediaQuery } from "@mui/material";
import all_properties from "../../fake_data/properties.json";
import user_offers from "../../fake_data/user_offers.json";
import HouseLoadingSkeleton from "../skeletons/ClassifiedsHouseCard";
import { useState } from "react";
import ClassifiedsHouseCard from "../skeletons/ClassifiedsHouseCard";
import { useTheme } from "@emotion/react";


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `offers-tab-${index}`,
  };
}


export default function OffersPage() {
    const [currentTab, setCurrentTab] = useState(0);
    const [selectedProperty, setSelectedProperty] = useState(null);

    const theme = useTheme();
    const smallSized = useMediaQuery(theme.breakpoints.down('md'));
    const superSized = useMediaQuery(theme.breakpoints.up('xl'));

    const smallEnough = smallSized || superSized;


    const offered_properties = ["a7b3d5f2-6f3b-4c7d-bc9e-d2e4f3a5e7c8", "e5a7d9b9-3d2a-4d34-8c7e-13f3d2a8c7e4"];

    const properties = all_properties.filter( p => offered_properties.includes(p.id));
    
    const handleChange = (event, newValue) => {
      setCurrentTab(newValue);
    };

    const ActionButtons = () => {
      return (
        <Stack p={2} spacing={2}>
          <Chip label={"Rejected"}/>
          <Button fullWidth variant="outlined">Increase Offer</Button>
          <Button fullWidth variant="outlined">Cancel Offer</Button>
        </Stack>
      )
    }


    return (
    
    <Container>
      <Stack spacing={2} py={2}>
        <Paper>
          <Box p={2}>
            <FormControl size="small" fullWidth>
              <TextField icdefsaq œd="filled-basic" label="Offer Code" variant="filled" />
            </FormControl>
            <Typography variant="caption">
              For private sales, please enter the special code the property seller either emailed or told you. This will look up the property.
            </Typography>
          </Box>
        </Paper>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Pending Offers" {...a11yProps(0)} />
            <Tab label="Bookmarks" {...a11yProps(1)} />
            <Tab label="Offer History" {...a11yProps(1)} />
          </Tabs>
        </Box>

        {/* BOOKMARKS */}
        <TabPanel value={currentTab} index={1}>
          <Grid container spacing={2}>

            {
              all_properties.filter(p => p.seller_user_id == 456).map( p=> 

                <ClassifiedsHouseCard property={p} xlSize={6}/>

              )
            }
          </Grid>
        </TabPanel>


        {/* PENDING OFFERS */}
        <TabPanel value={currentTab} index={0}>
        <Stack spacing={2}>

          
          {
            properties.map((p) =>
              <Paper sx={{
                  overflow:'hidden',
                  ":hover": {
                    boxShadow: 8, // theme.shadows[20]
                  }
                }}>
                <Stack direction={"row"}>
                  <Box sx={{maxWidth: '150px', aspectRatio: '1', objectFit:'cover'}} component="img" src={p.images[0]}/>
                  {/* Description */}
                  <Box p={2} sx={{textAlign:"left", flex:'1'}}>
                    <Typography variant="h5">{p.address1}</Typography>
                    <Typography variant="caption">{p.description}</Typography>
                    <Typography variant="body1">Listing: ${p.asking_price.toLocaleString('en-ca')}</Typography>
                    <Typography variant="body2">Your Offer: ${(p.asking_price-(p.asking_price*0.1)).toLocaleString('en-ca')}</Typography>
                  </Box>

                  {
                    !smallEnough &&
                    ActionButtons()
                  }
                
                </Stack>
                {
                  smallEnough &&
                  ActionButtons()
                }
              </Paper>    
            )
         }
        </Stack>
        </TabPanel>
        

      </Stack>
    </Container>
  )
}
