import { useState } from "react";
import buyImage from "../img/buy.png";
import sellImage from "../img/sell.png";
import BuyModal from "./BuyModal";
import SellModal from "./SellModal";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  ListItemText,
  Container,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";

function BuySellPage() {
  const [sellModalOpen, setSellModalOpen] = useState(false);
  const [buyModalOpen, setBuyModalOpen] = useState(false);
  const theme = useTheme();
  const bigEnough = useMediaQuery(theme.breakpoints.up('sm'));

  const handleOnBuyOpen = () => {
    setBuyModalOpen(true);
  };

  const handleOnSellOpen = () => {
    setSellModalOpen(true);
  };

  const handleOnBuyClose = () => {
    setBuyModalOpen(false);
  };

  const handleOnSellClose = () => {
    setSellModalOpen(false);
  };


  return (
    <Box
    className="BuySellPage Page"
    style={{
      height: "100%",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
    >
        <Container sx={{maxWidth:800}}>
          <Grid p={5} container spacing={2}>
            <Grid item xs={12} sm={6} md={6} sx={{ maxWidth: 600 }}>
              <Card
                onClick={() => handleOnBuyOpen()}
                sx={{
                  ":hover": {
                    boxShadow: 20, // theme.shadows[20]
                  }
                }}
              >
                <CardMedia sx={{ height: {xs: 100, sm:300} }} image={buyImage} />
                <CardContent>
                  <Typography gutterBottom variant={bigEnough ? "h2" : "h5"} component="div" sx={{  }}>
                  <strong>BUY</strong>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Browse hundreds of local listings or make an offer and start the
                    buying process today!
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card
                onClick={() => handleOnSellOpen()}
                sx={{
                  ":hover": {
                    boxShadow: 20, // theme.shadows[20]
                  },
                }}
              >
                <CardMedia sx={{ height: {xs: 100, sm:300} }} image={sellImage} />
                <CardContent>
                  <Typography gutterBottom variant={bigEnough ? "h2" : "h5"} component="div">
                    <strong>SELL</strong>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Sell your property through an advertisement or begin accepting
                    offers on your private sale
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          </Container>
          <BuyModal open={buyModalOpen} handleClose={() => handleOnBuyClose()} />
          <SellModal open={sellModalOpen} handleClose={() => handleOnSellClose()} />
      </Box>
  );
}
export default BuySellPage;
