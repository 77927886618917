import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import logo from "../img/logo-hero.png";
import { Link } from "react-router-dom";
import { Badge, ButtonGroup, Snackbar } from "@mui/material";
import avatarImage from "../img/avatar.webp"
import { useAuth } from "../contexts/AuthContext";
import { useBookmarks } from "../contexts/BookmarksContext";
const settings = ["Profile", "Account & Privacy", "Saved", "Log Out"];

function NavBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const {userValue, logIn, fetchProfile, logOut} = useAuth();

  const {currentUser} = userValue;

  const {openBookmarks} = useBookmarks();

  useEffect( () => {
    handleLogin(123);
  }, [])

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const invoke = (option) => {
    switch(option) {
      case "Log Out":
        logOut();
        break;
      case "Saved":
        openBookmarks();
        break;
      }
    handleCloseUserMenu();
  }

  const handleLogin = () => {
    logIn(123);
    setAlertMessage('Welcome back!');
  }

  const onCloseAlertMessage = () => {
    setAlertMessage(null)
  }

  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: "white",
        borderBottom: "1px solid #ccc",
        boxShadow: "0px 3px 10px rgba(0,0,0,0.1)",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box mr={2}>
            <Link to="/">
              <Box
                component="img"
                src={logo}
                sx={{ height: "70px", width: "auto" }}
              />
            </Link>
          </Box>
         
         
         
         
          {/* Badges for Buying/Sellign Processes Go Here */}
          <ButtonGroup size="small" ml={5} variant="outlined" aria-label="Basic button group">
            <Badge badgeContent={1} color="secondary">
              <Link to="/sell/">
                <Button>Selling</Button>
              </Link>
            </Badge>
            <Badge badgeContent={1} color="secondary">
              <Link to="/buy/">
                <Button>Buying</Button>
              </Link>
            </Badge>
          </ButtonGroup>

          <Box
            sx={{
              flex: 1,
            }}
          ></Box>
          <Box className="rightNavBox"></Box>


          <Box sx={{ flexGrow: 0 }}>
            {
              !currentUser &&
              <Button variant="contained" color="secondary" onClick={() => handleLogin(123)}>Log In</Button>
            }
            {
              // If User is Logged In
              currentUser && <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Quintan" src={avatarImage} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem onClick={() => invoke(setting)} key={setting}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu> 
              </>
            }
          </Box>
        </Toolbar>
      </Container>


      {/* Alerts for after and before sign in */}
      <Snackbar
        open={alertMessage != null}
        autoHideDuration={500}
        onClose={onCloseAlertMessage}
        message={alertMessage}
      />
    </AppBar>
  );
}
export default NavBar;
