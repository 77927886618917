import { Box, Button, Card, CardContent, CardMedia, Container, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import processes from "../../fake_data/buy_progress.json";
import all_properties from "../../fake_data/properties.json"
import { useState } from "react";
import ProgressStepSquare from "./ProgressStep";
import { ChatBubble } from "@mui/icons-material";

export default function BuyProcessMaster() {

  const {property_id} = useParams();
  const [currentProcess, setCurrentProcess] = useState(processes[0]);
  const currentProperty = all_properties.filter(p=>p.id == currentProcess.property_id)?.[0];
  console.log(currentProperty)

  return (
    <Box
    className="BuySellPage Page"
    style={{
      height: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
    }}
    >
        <Container>
          <Stack direction="row" py={2} spacing={2}>

            <Card sx={{maxWidth:200}}>
              <CardMedia component="img"
                height="140" image={currentProperty.images[0]}/>
                <CardContent sx={{textAlign:'left'}}>
                  <Box mb={2}>
                    <Button variant="outlined"><ChatBubble/> Chat with Seller</Button>
                  </Box>

                  <Typography variant={"body1"} textAlign={"left"}>${currentProperty.address1}</Typography>
                  <Typography variant={"body1"} textAlign={"left"}>${currentProperty.asking_price.toLocaleString('en-ca')}</Typography>
                  <Divider/>
                  <Typography variant={"caption"} textAlign={"left"}>${currentProperty.description}</Typography>
                </CardContent>
            </Card>
            <Box flex={1}>
              <Typography variant={"h5"} px={4} textAlign={"left"}>Buying Property: {currentProperty.address1}</Typography>
              
              <Typography variant={"subtitle1"} color="text.secondary" px={4} textAlign={"left"}>{currentProperty.title}</Typography>
              <Typography variant={"subtitle2"} color="text.secondary" px={4} textAlign={"left"}>{currentProperty.description}</Typography>

              <Box>
                <Link to={`/ads/${currentProperty.id}`}>
                  <Button>View Original Listing</Button>
                </Link>
              </Box>
              <Divider/>
              <Grid p={5} container spacing={2}>
                  {currentProcess.steps.map(s => 
                    <ProgressStepSquare type="buy" property_id={currentProcess.property_id} step={s}/>
                  )}
              </Grid>
            </Box>
          </Stack>
          </Container>
          
      </Box>
  )
}
