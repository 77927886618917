import * as React from "react";
import {
  Modal,
  Box,
  Typography,
  Paper,
  Stack,
  CardContent,
  Card,
  Button,
} from "@mui/material";
import AnimatedModal from "./AnimatedModal";
import { Link } from "react-router-dom";
import { EmojiPeople, SavedSearch } from "@mui/icons-material";

import BrowseAdsImg from "../img/search_houses.webp";
import MakeOfferImg from "../img/make_offers.webp";
import LinkCardButton from "./LinkCardButton";
function BuyModal({ open, handleClose = () => {} }) {
  return (
    <AnimatedModal
      closeAfterTransition
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      title={"Buy Something"}
    >
      
          <Stack gap={2}>
            <LinkCardButton
              image={BrowseAdsImg}
              linkTo="/ads"
              primary="Browse Ads"
              secondary="Browse ads and find properties to purchase"
            />
            <LinkCardButton
              image={MakeOfferImg}
              linkTo="/offers/"
              primary="Make & Manage Offers"
              secondary="Make offers on saved properies and manage existing amounts"
            />
        </Stack>    
      
      
    </AnimatedModal>
  );
}
export default BuyModal;
