import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  Paper,
  Stack,
  Select,
  Chip,
  Divider,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import AppBanner from "./AppBanner";
import SearchStack from "./ads/SearchStack";
import { Book, Bookmark, BookmarkAdd, ChatBubble, ChatBubbleOutline, CurrencyExchange, HeartBrokenOutlined, KeyboardArrowLeft, Map, Send, SortOutlined } from "@mui/icons-material";
import HouseLoadingSkeleton from "./skeletons/ClassifiedsHouseCard";

import all_properties from "../fake_data/properties.json";
import ClassifiedsHouseCard from "./skeletons/ClassifiedsHouseCard";

function AdDetailPage() {
    const {property_id} = useParams();

    const property = all_properties.filter(p => p.id == property_id)?.[0];

    const similar_listings = all_properties.filter(p => p.type == property.type).slice(0, 4);

    return (
      
        <Container sx={{overflow:'hidden'}}>

          <Box my={3} sx={{position:'absolute', zIndex:3000, display:'flex', justifyContent:'left'}}> {/* Ad Header */}
            <Link to="/ads"><Button color="secondary" variant="outlined"><KeyboardArrowLeft/> Back to Ads</Button></Link>
          </Box>

          <Box
            zIndex={1}
            mx={-5}
            sx={{ opacity:'0.3', position:'relative', backgroundImage: `url(${property.images[0]})`, filter:'blur(20px)', height:'150px', backgroundAttachment:'center center',backgroundSize:'cover'}}        
        ></Box>
        <Grid mt={-10} container spacing={1} zIndex={3} position={'relative'}>
          <Grid item xs={12} sm={4} md={4} >
            <Card>        
              <CardMedia component="img" image={property.images[0]} sx={{display:'block', width:'100%'}}/>
              <CardContent>
                <Stack spacing={1}>
                  <Button variant="outlined">
                    <BookmarkAdd/> Add to Bookmarks
                  </Button>
        
                  <Button variant="outlined">
                    <ChatBubbleOutline/> Message Seller
                  </Button>

                  <Button variant="outlined">
                    <CurrencyExchange/> Make an Offer
                  </Button>
                </Stack>
                
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={8} md={8} >
            <Box p={2}>
              <Box p={2}>
                <Typography align="left" variant={'h3'}>{property.title}</Typography>
                <Typography align="left" variant={'body1'} color="text.secondary">{property.description}</Typography>
              </Box>
              <Divider/>
              <Box p={2}>
                <Typography align="left" variant={'body2'} color="text.secondary">{property.address1}</Typography>
                <Typography align="left" variant={'body2'} color="text.secondary">Victoria, BC</Typography>
              </Box>
              <Divider/>



              {/* Property Specs */}

              <Grid  mt={3} container spacing={1} zIndex={3} position={'relative'}>
                <Grid item xs={6} sm={4} md={4} >
                  <Box>
                    <Typography variant="body2" color="text.secondary">Asking Price</Typography>  
                    <Typography variant={'h4'}>${property.asking_price.toLocaleString('en-ca')}</Typography>  
                  </Box>
                </Grid>
                <Grid item xs={6} sm={4} md={4} >
                  <Box>
                    <Box>
                      <Typography variant="body2" color="text.secondary">Layout</Typography>  
                      <Chip sx={{fontSize:'0.4em'}} label={`${property.floors} fl`}/>
                      <Chip sx={{fontSize:'0.4em'}} label={`${property.bedrooms} bed`}/>
                      <Chip sx={{fontSize:'0.4em'}} label={`${property.bathrooms} bath`}/>
                      <Chip sx={{fontSize:'0.4em'}} label={`${property.sqft} sqft`}/>          
                    </Box>
                  </Box>
                </Grid>


                <Grid item xs={6} sm={4} md={4} >
                  <Box>
                    <Box>
                      <Typography variant="body2" color="text.secondary">Listing Info</Typography>  
                      <Typography variant="body1" color="">Posted 7 Weeks Ago</Typography>  
                    </Box>
                  </Box>
                </Grid>
              </Grid>



            </Box>
          </Grid>

          
        </Grid>
      


        <Box mt={5}>
          <Typography align="left" variant={'h5'}>Similar Listings</Typography>
          <Typography align="left" variant={'body2'} color={"text.secondary"}>Properties around this price range and type</Typography>
          <Grid container spacing={1} zIndex={3} position={'relative'}>
            {
              similar_listings.map( p => 
                
                <ClassifiedsHouseCard property={p} newTabOnClick={true}/>

              )
            }
          </Grid>
        </Box>

      </Container>

      
      
  );
}
export default AdDetailPage;
