import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stepper,
  Step,
  StepLabel,
  MobileStepper,
  useMediaQuery
} from "@mui/material";
import all_properties from "../fake_data/properties.json";
import { AddAPhoto, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useTheme } from "@emotion/react";


function MultiImageUpload() {
  const sample_images = all_properties.map( p => p.images[0]).slice(0,5);
  const theme = useTheme(); 
  const bigEnough = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    
    <Box className="Sell New" style={{ width:'100%' }}>
        <ImageList sx={{ width: 'auto' }} cols={bigEnough ? 3 : 2}>
            {sample_images.map((item) => (
                <ImageListItem vairant="masonry" key={item}>
                <img
                    sx={{aspectRatio:1}}
                    srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item}?w=164&h=164&fit=crop&auto=format`}
                    loading="lazy"
                    />
                </ImageListItem>
            ))}
            <ImageListItem key={'images-add-more'} sx={{aspectRatio:1}}>
            <IconButton color="secondary" sx={{height:'100%'}}>
                <AddAPhoto/>
            </IconButton>
            </ImageListItem>
        </ImageList>
      </Box>
  );
}
export default MultiImageUpload;
