import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { ArrowLeft, BookmarkAdd, BookmarkRemove } from "@mui/icons-material";
import {BookmarksProvider, useBookmarks} from "../contexts/BookmarksContext";

function BookmarkToggleButton({property, openModalAfterAdding=false}) {

  const {addBookmark, removeBookmark, openBookmarks, currentBookmarks } = useBookmarks();

  const isBookmarked = React.useMemo(() => {
    return currentBookmarks.filter(p => p.id == property.id).length > 0
  }, [currentBookmarks]);

  const handleClick = (e) => {
    e.stopPropagation();
    isBookmarked ? removeBookmark(property) : addBookmark(property);
  }

  const bookmarkIcon = () => {
    return isBookmarked ?  <BookmarkRemove/> : <BookmarkAdd/>;
  }

  return (
    <IconButton onClick={handleClick} sx={{pointerActions:''}}>
        {bookmarkIcon()}
    </IconButton>
  );
}
export default BookmarkToggleButton;
