import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import {fakeResponse} from "../helpers/fakeApi";
export const chat_user_types = {
  buyer: 'buyer',
  seller: 'seller'
}
import all_chat from "../fake_data/chat.json";
import all_users from "../fake_data/users.json";
import all_properties from "../fake_data/properties.json";
import { useAuth } from './AuthContext';

const ChatContext = createContext();
const ChatProvider = ({ children }) => {
const [currentConversation, setCurrentConversation] = useState(null);
const [messageListOpen, setMessageListOpen] = useState(false);
const [conversations, setConversations] = useState([]);

const currentConversationValue = useMemo(() => ({ currentConversation, setCurrentConversation }), [currentConversation]);
const messagesListOpenValue = useMemo(() => ({ messageListOpen, setMessageListOpen }), [messageListOpen]);
const conversationsValue = useMemo(() => ({ conversations, setConversations }), [conversations]);

  const _buildUserMessage = (user, message) => {
    console.log(message);
    return {
      sender: user, // buyer | seller
      text: message,
      sent_at: +Date()
    }
  }

  // Get the active user to avoid passing in new values
  const {userValue} = useAuth();
  const {currentUser} = userValue;

  const setUpMessages = (d) => {
    setCurrentConversation(null)
    fetchConversations().then(d => setConversations(d) )
  }

  const fetchConversations = () => {
    return fakeResponse(all_chat.map(c => {
      const s = all_users.filter(u => u.id == c.seller_id)[0];
      const p = all_properties.filter(p => p.id == c.property_id)[0];
      return {
        ...c,
        seller:s,
        property:p
      }
    }))
  }

  const fetchConversation = (conversation) => {
    return fakeResponse(all_chat.filter(c => c.id == conversation.id))
  }

  const handleSetActiveConversation = () => {

  }

  const fetchChat = (property_id) => {
    // return fakeResponse(currentConversation).then( d => setUpMessages(d));
  }

  const postMessage = (message) => {
    return fakeResponse().then(d => setC)
    setMessages([...currentConversation, d, _buildUserMessage("buyer", _createFakeResponse(currentConversation.length))])
  }

  return (
    <ChatContext.Provider value={{ currentConversationValue, setUpMessages, messagesListOpenValue, conversationsValue, fetchConversations }}>
      {children}
    </ChatContext.Provider>
  );
};

// Custom hook to use the API context
const useChatApi = () => {
  return useContext(ChatContext);
};

export { ChatProvider, useChatApi };
