import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import {fakeResponse} from "../helpers/fakeApi";
export const chat_user_types = {
  buyer: 'buyer',
  seller: 'seller'
}
import { useAuth } from './AuthContext';
import AnimatedModal from '../components/AnimatedModal';
import { Box, Stack, Typography } from '@mui/material';
import { BookmarkAdd } from '@mui/icons-material';


/* A catchall for all the random small UI stuff that might need to be called from anywhere */
const BookmarksContext = createContext();

const BookmarksProvider = ({ children }) => {

  const [bookmarks, setBookmarks] = useState([]);
  const [bookmarksModalOpen, setBookmarksModalOpen] = useState(false);
  const bookmarksValue = useMemo(() => ({ bookmarks, setBookmarks }), [bookmarks]);
  
  const addBookmark = (property) => {
    const b = {...property, added_at:+Date()}
    const result = [...bookmarks, b]
    setBookmarks(result)
    return result 
  }
  const removeBookmark = (property) => {
    const result = bookmarks.filter( p => p.id != property.id)
    setBookmarks(result)
    return result;
  }

  const openBookmarks = () => {
    setBookmarksModalOpen(true);
  }

  const currentBookmarks = bookmarksValue.bookmarks;
  
  return (
    <BookmarksContext.Provider value={{ currentBookmarks, removeBookmark, addBookmark, openBookmarks  }}>
      {children}
      <AnimatedModal
            closeAfterTransition
            open={bookmarksModalOpen}
            onClose={() => setBookmarksModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            title={"Bookmarked Properties"}
            >

              {
                currentBookmarks.length == 0 && 
                <Box  >
                  <Typography color="text.secondary" textAlign={'center'} variant='body1'>Nothing bookmarked yet. If you see something you like, hit the <BookmarkAdd/> button.</Typography>
                </Box>
              }
            <Stack gap={2}>
              

                
                {
                    currentBookmarks.map(() => {
                        return (
                            <ListItem>
                                asd
                            </ListItem>
                        )
                    })
                }
                
            </Stack>    
        </AnimatedModal>
    </BookmarksContext.Provider>
  );
};

// Custom hook to use the API context
const useBookmarks = () => {

    /* A catchall for all the random small UI stuff that might need to be called from anywhere */
  return useContext(BookmarksContext);
};


export { BookmarksProvider, useBookmarks };
