
import { ChatBubble, KeyboardArrowLeft } from "@mui/icons-material";
import { Avatar, Badge, Box, Button, Card, Fab, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useChatApi } from "../../contexts/ChatContext";
import ConversationView from "./ConversationView";

const fabStyle = {
    position: "fixed",
    bottom: 5,
    right: 5
}

export default function MiniChat({onBackButton, isCompact}) {


     // Otherwise, do this
     const { messagesListOpenValue,
        conversationsValue,
        currentConversationValue, 
        fetchConversations 
      } = useChatApi();

    const { currentConversation, setCurrentConversation } = currentConversationValue;
    const { messageListOpen, setMessageListOpen } = messagesListOpenValue;
    const { conversations, setConversations } = conversationsValue;

    const handleBackButton = () => {
        console.log("BAAAAACK?")
        onBackButton(currentConversation);
    }
    
    const onHandleConversationChange = (conversation) => {
        console.log(conversation)
        setCurrentConversation(conversation)
    }
    
    const selectedItemStyle = (c) => {
       return currentConversation?.id == c.id ? {background:"#eaeaea"} : {}
    }

    return (
        <Box style={{flex:'1'}}>
            {
                isCompact && // Hide this if the screen is big
                <Box>
                    <Button onClick={() => handleBackButton()} variant="outlined"><KeyboardArrowLeft/> Back</Button>
                </Box>
            }
            <Paper elevation={0} sx={{
                    background: '#eaeaea', 
                    position:'relative', 
                    boxShadow: 'inset 0px 1px 3px rgba(0,0,0,0.15);', 
                    minHeight:'400px', 
                    height: '100%', 
                    width:'100%'
                }}>
                <Box sx={{
                    height:'100%',
                    position: 'absolute', // Weird workaround because I can't get this to span the full height
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}>
                    <ConversationView conversation={currentConversation}/> 
                </Box>
            </Paper>
        </Box>
  );
}
