import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import HouseForSale from "../../img/forsale.webp";

import {
  Badge,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  useMediaQuery,
} from "@mui/material";

import { Link, useLocation, useNavigate } from "react-router-dom";
import AppBanner from "../AppBanner";

import all_properties from '../../fake_data/properties.json';
import { Edit } from "@mui/icons-material";
import property_processes from "../../fake_data/sell_progress.json";
import { useTheme } from "@emotion/react";
import SellingPropertyOverviewCardQuickStats from "./SellPropertyOverviewCardQuickStats";
import SellingPropertyOverviewCardActions from "./SellingPropertyOverviewCardActions";
function SellingPropertyOverviewCard({property, isCompact}) {


  const navigate = useNavigate();
  const linkToPropertySaleProgress = `/sell/${property.id}/process`

  return (
        <Card sx={{ display: isCompact ? 'block' : 'flex' }}>
            <CardMedia
                component="img"
                sx={{ width: isCompact ? '100%' : 200, height: isCompact ? '200px' : 'auto' }}
                image={property.images[0]}
                alt="For Sale Sign on Lawn"
                />
            <Box sx={{ flex: '1 0 auto', textAlign: 'left' }}>
                <Stack direction={ isCompact ? "column" : "row"} sx={{fontSize: {sm: '0.1rem', md:'1rem' }}}>
                    <Box p={1} flex={1}>
                        <Typography variant="body2">{property.title}</Typography>
                        <Typography color={'text.secondary'} variant="caption">{property.address1}</Typography>
                    </Box>
                    <Box p={1} justifyItems={isCompact ? 'center' : 'right'}>
                        <SellingPropertyOverviewCardActions property={property}/>
                    </Box>
                </Stack>
                <CardContent>
                    <Box>
                        <SellingPropertyOverviewCardQuickStats property={property}/>
                    </Box>
                </CardContent>
            </Box>
            </Card>
  );
}
export default SellingPropertyOverviewCard;
