import { Box, Typography } from "@mui/material";

import Image404 from "../../img/404.png";
import { Link } from "react-router-dom";

export default function NotFoundPage() {
    return (
      <Box
        flex={1}
        sx={{ background: "#d2ccc7", height: "100%", alignContent: "center" }}
      >
        <Box sx={{ 
                backgroundImage:`url(${Image404})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition:'center center',
                height: "calc(100% - 90px)",   
                alignContent:'center',

        }}>
          <Typography
            position={"relative"}
            zIndex={1000}
            variant="h1"
            sx={{
              color: "white",
              textShadow: '0px 2px 10px rgba(0,0,0,0.85)',
            }}
          >
            404
          </Typography>
          <Typography
            position={"relative"}
            zIndex={1000}
            variant="subtitle1"
            sx={{
              color: "white",
              textShadow: '0px 2px 10px rgba(0,0,0,0.85)',
            }}
          >
            Page Not Found
          </Typography>
        </Box>
        <Link to="/">Go Back to Main</Link>
      </Box>
    );
  }
  