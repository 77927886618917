
import { ChatBubble } from "@mui/icons-material";
import { Badge, Fab, Stack, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import MiniMessages from "../components/chat/MiniMessages";
import MiniChat from "../components/chat/MiniChat";
import AnimatedModal from "../components/AnimatedModal";
import { useChatApi } from "../contexts/ChatContext";
import { useAuth } from "../contexts/AuthContext";
import { useTheme } from "@emotion/react";

const fabStyle = {
    position: "fixed",
    bottom: 5,
    right: 5
}

export default function ChatContainer() {
    const theme = useTheme();
    const bigEnough = useMediaQuery(theme.breakpoints.up('md'));  
    const [chatOpenMobile, setChatOpenMobile] = useState(false);
    const chatVisible = (chatOpenMobile);

    /* Hide if we don't have a user */
    const {userValue} = useAuth();
    const {currentUser} = userValue;
    


    // Otherwise, do this
    const { messagesListOpenValue,
            setUpMessages,
            currentConversationValue,
            conversationsValue
          } = useChatApi();

    const { messageListOpen, setMessageListOpen } = messagesListOpenValue;
    const {currentConversation, setCurrentConversation} = currentConversationValue;

    useEffect(() => {
        setUpMessages()
    }, []);


    const handleMessageListOpen = () => {
        setMessageListOpen(true);
    }

    const handleMessageListClose = () => {
        setMessageListOpen(false);
    }

    const handleBackButtonPress = (chat) => {
        console.log(">>>> BACK!")
        setChatOpenMobile(false);
        setCurrentConversation(null);

    }

    const handleSelectChat = (chat) => {
        setChatOpenMobile(true);
        setCurrentConversation(chat);
    }
    
    if(!currentUser) return <></>

    return (

    <>
        <Fab style={fabStyle} size="small" color="primary" aria-label="add">
            <Badge badgeContent={1} color="secondary">
                <ChatBubble onClick={() => handleMessageListOpen()} sx={{color:'#fff', textShadow:'0px 1px 5px rgba(0,0,0,0.5)'}}/>
            </Badge>
        </Fab>
        
        <AnimatedModal
            width={'100%'}
            maxWidth={'800px'}
            closeAfterTransition
            open={messageListOpen}
            onClose={() => handleMessageListClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            title={"Messages"}
            contentStyle={{px:0}}
            p={0}
        >
            <Stack direction="row">
                {
                    (!chatVisible || bigEnough) &&
                    <MiniMessages onSelectChat={(chat) => handleSelectChat(chat)} isCompact={!bigEnough}/>
                }
                {
                    (chatVisible || bigEnough) &&
                    <MiniChat onBackButton={(chat) => handleBackButtonPress(chat)} isCompact={!bigEnough}/>
                }
            </Stack>
        </AnimatedModal>
    </>

  );
}
