import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { BrowserRouter, Routes, Route, Navigate, Link } from "react-router-dom";

import BuySellPage from "./components/BuySellPage";
import Sidebar from "./components/Sidebar";
import AdsPage from "./components/AdsPage";
import BuyWizardPage from "./components/pages/BuyWizardPage";
import UserAccount from "./components/UserAccount";
import Image404 from "./img/404.png";
import "./styles.css";
import NotFoundPage from "./components/error_pages/NotFoundPage";
import NewOffer from "./components/NewOffer";
import OffersPage from "./components/offers/OffersPage";
import PropertyOffer from "./components/offers/PropertyOffer";
import BuyProcessMaster from "./components/offers/BuyProcessMaster";
import SellProcessDetail from "./components/offers/SellProcessDetail";
import SellProcessMaster from "./components/offers/SellProcessMaster";
import BuyProcessDetail from "./components/offers/BuyProgressDetail";
import SellWizardPage from "./components/pages/SellWizardPage";
import AdDetailPage from "./components/AdDetailPage";
import SellNew from "./components/pages/SellNew";


export default function AppRoutes() {
  const [sidebarOpen, setSidebarOpen] = useState(true); // Use screen size

  return (
    <Routes>
        <Route path="/" element={<BuySellPage />} />

        {/* Ads Page */}
        <Route path="/ads" element={<AdsPage />} />           {/* Skeleton - needs loops */}
        <Route path="/ads/:property_id" element={<AdDetailPage />} />           {/* Skeleton - needs loops */}

        {/* Buy/Sell Homepages */}
        <Route path="/buy" element={<BuyWizardPage />} />       {/* Redirects to only purchase event */}
        <Route path="/sell" element={<SellWizardPage />} />     {/* Redirects to only sell event */}

        {/* Overall Offers */}
        <Route path="/offers" element={<OffersPage />} />       {/* Not touched */}

        {/* Details on each Offer - Show a history of offers here too */}
        <Route path="/offers/:property_id" element={<PropertyOffer />} /> {/* Not touched */}

        {/* Buying Process - References the 2sid of buy_progress in demo content */}
        <Route path="/buy/:property_id/process" element={<BuyProcessMaster />} />
        <Route path="/buy/:property_id/process/:step_id" element={<BuyProcessDetail />} />

        {/* Selling Process - References the id of sell_progress in demo content */}
        <Route path="/sell/new" element={<SellNew />} />
        <Route path="/sell/:property_id/process" element={<SellProcessMaster />} />
        <Route path="/sell/:property_id/process/:step_id" element={<SellProcessDetail />} />


      {/* Account Pricess */}
        <Route path="/account" element={<UserAccount />} />
        <Route path="/property/:property_id" element={<UserAccount />} />

        <Route path="/404" element={<NotFoundPage />} />

        <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  )
}
