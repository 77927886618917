import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import HouseForSale from "../../img/forsale.webp";

import {
  Badge,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AppBanner from "../AppBanner";

import all_properties from '../../fake_data/properties.json';
import { Edit } from "@mui/icons-material";
import SellingPropertyOverviewCard from "../selling/SellingPropertyOverviewCard";
import { useTheme } from "@emotion/react";

function SellWizardPage() {
  const theme = useTheme();
  const bigEnough = useMediaQuery(theme.breakpoints.up('md'));  
  const navigate = useNavigate();
  // setTimeout(  () => {
  //   navigate("/sell/1234/process")
  // }, 2000)

  const properties_selling = all_properties.filter( p => p.seller_user_id == 123)

  const hideWhenBig   = {display:{xs: 'block', md: 'none'} };
  const hideWhenSmall = {display:{xs: 'none', md: 'block'} };

  return (
    <Box className="BuyWizard Page" style={{ flex: 1 }}>
      <AppBanner 
        title="Sell A Property"
      />

        <Container py={2}>

          <Box py={2}>
            <Box p={2}>
              <Typography component="div" variant="h5">
                Jump Back In
              </Typography>

              <Typography variant="subtitle1" color="text.secondary" component="div">
                Resume the selling process on your current properties
              </Typography>
            </Box>
            {/* Loop through the properties you have for sale and resume the selling process */}
            {
              properties_selling.map( p => 
                  <SellingPropertyOverviewCard isCompact={!bigEnough} property={p}></SellingPropertyOverviewCard>
                )
              }

          </Box>

          <Box py={2}>

            <Card sx={{ display: 'flex' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 1 auto' }}>
                  <CardMedia
                    component="img"
                    sx={{...hideWhenBig}}
                    image={HouseForSale}
                    alt="For Sale Sign on Lawn"
                    />

                  <Box p={2}>
                    <Typography component="div" variant="h5">
                      Sell your home with us!
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                      We strive to make the process of selling your home as easy as possible by keeping up to date with the latest paperwork and requirements for a sale. If you need help finding anyone to assist you with the process, we've got your back.
                    </Typography>

                    <Typography variant="subtitle1" color="text.secondary" component="div">
                      Whether you want to create a public listing or just a placeholder for your home sale, start by clicking on the link below.
                    </Typography>
                  </Box>
                  <Divider/>
                  <Box p={2}>
                    <Button size="large" variant="contained" color="secondary">Create a Listing</Button>
                  </Box>

                </CardContent>
              </Box>
              <CardMedia
                component="img"
                sx={{ width: 300, ...hideWhenSmall}}
                image={HouseForSale}
                alt="For Sale Sign on Lawn"
                />

                
            </Card>
          </Box>

        </Container>
    </Box>
  );
}
export default SellWizardPage;
