
import { ChatBubble } from "@mui/icons-material";
import { Avatar, Badge, Box, Card, Fab, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useChatApi } from "../../contexts/ChatContext";
import ConversationView from "./ConversationView";

const fabStyle = {
    position: "fixed",
    bottom: 5,
    right: 5
}

export default function MiniMessages({onSelectChat, isCompact}) {


     // Otherwise, do this
    const { messagesListOpenValue,
        conversationsValue,
        currentConversationValue, 
        setUpMessages,
        fetchConversations 
    } = useChatApi();

    const { currentConversation, setCurrentConversation } = currentConversationValue;
    const { messageListOpen, setMessageListOpen } = messagesListOpenValue;
    const { conversations, setConversations } = conversationsValue;

    
    const onHandleConversationChange = (conversation) => {
        onSelectChat(conversation);
    }
    
    const selectedItemStyle = (c) => {
       return currentConversation?.id == c.id ? {background:"#eaeaea"} : {}
    }

    return (
        <Box sx={{overflowY: 'scroll', maxHeight: '400px', width: (isCompact ? '100%' : '300px')}}>

            <List sx={{height: '100%'}}>
                {
                    conversations.map( (c) =>
                        <ListItemButton
                                sx={{ ...selectedItemStyle(c) }}
                                onClick={() => onHandleConversationChange(c)}
                            >
                            <ListItemAvatar>
                                <Avatar src={c.property.images[0]}></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={c.seller.name} secondary={"Monday"}></ListItemText>
                        </ListItemButton>
                    )
                }
            </List>
        </Box>
        

  );
}
