import { ThemeOptions } from '@mui/material/styles';

export const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#72d2d8',
      
    },
    secondary: {
      main: '#e91e63',
      
    },
  },
};