import * as React from "react";
import {
  Modal,
  Box,
  Typography,
  Paper,
  Stack,
  CardContent,
  Card,
  Button,
  CardMedia,
} from "@mui/material";
import AnimatedModal from "./AnimatedModal";
import { Link } from "react-router-dom";
import { EmojiPeople, House, MapsHomeWork, SavedSearch, Star } from "@mui/icons-material";
import SellerDashboardImg from "../img/seller_dashboard.webp"
import NewAdImg from "../img/make_ad.webp"
import LinkCardButton from "./LinkCardButton";
function SellModal({ open, handleClose = () => {} }) {
  return (
    <AnimatedModal
      closeAfterTransition
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      title={"Sell a Property"}
    >
      <Stack gap={2}>
        <LinkCardButton
          image={NewAdImg}
          linkTo="/sell/new"
          primary="Create an Ad"
          secondary="List your property privately or create a public ad"
        />
        <LinkCardButton
          image={SellerDashboardImg}
          linkTo="/sell/"
          primary="Seller Dashboard"
          secondary="View and manage your listings and progress"
        />
        
      </Stack>    
    </AnimatedModal>
  );
}
export default SellModal;
