import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import HouseForSale from "../../img/forsale.webp";

import {
  Badge,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemButton, 
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  useMediaQuery,
} from "@mui/material";

import { Link, useLocation, useNavigate } from "react-router-dom";
import AppBanner from "../AppBanner";

import all_properties from '../../fake_data/properties.json';
import { Edit } from "@mui/icons-material";
import property_processes from "../../fake_data/sell_progress.json";
import { useTheme } from "@emotion/react";
import SellingPropertyOverviewCardQuickStats from "./SellPropertyOverviewCardQuickStats";
function SellingPropertyOverviewCardActions({property}) {
  const theme = useTheme();
  const smallSized = useMediaQuery(theme.breakpoints.down('md'));
  const superSized = useMediaQuery(theme.breakpoints.up('xl'));

  const smallEnough = smallSized || superSized;

  const navigate = useNavigate();
  const linkToPropertySaleProgress = `/sell/${property.id}/process`

  return (
    <Stack spacing={1} direction={"row"} justifyContent={smallEnough ? 'center':'right'}>
        <Link to={linkToPropertySaleProgress}>
            <Button variant="outlined">View Progress</Button>
        </Link>
        
        <Badge badgeContent="1" color="secondary">
            <Button variant="outlined">View Offers</Button>
        </Badge>
        <IconButton aria-label="Edit">
            <Edit />
        </IconButton>
        
    </Stack>
  );
}
export default SellingPropertyOverviewCardActions;
