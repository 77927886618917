import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stepper,
  Step,
  StepLabel,
  MobileStepper,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert
} from "@mui/material";
import { Link } from "react-router-dom";
import AppBanner from "../AppBanner";
import all_properties from "../../fake_data/properties.json";
import { AddAPhoto, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {flattenProcessForm, sell_form} from "../../processes/createListing";
import FormManager from "../FormManager";
import MultiImageUpload from "../MultiImageUpload";

const steps = [
  'Add Basic Information',
  'Add Photos',
  'Listing Details',
];

function SellNew() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [form, setForm] = React.useState(flattenProcessForm(sell_form));


  const handleNext = ()=> {
    setActiveStep(activeStep+1 < steps.length ? activeStep+1 : activeStep);
  }

  const handleBack = ()=> {
    setActiveStep(activeStep-1 > 0 ? activeStep-1 : 0);
  }

  const formIsValid = () => {
    return true;
  }

  const handleInputChange = (key, value) => {
    console.log(key, value)
    setForm({...form, [key]:value})
  }

  return (
      <Box className="Sell New" style={{ flex: 1 }}>
        <AppBanner
            title={sell_form.process_name}
        />
        {/* STEPPER */}
          <MobileStepper
            variant="text"
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            nextButton={
              // This should advance next until the end, then continue
              activeStep === steps.length-1 ?
                
                <></>

                :

                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === steps.length - 1}
                >
                  Next <KeyboardArrowRight />
                </Button>
              
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />



        <Container sx={{display:'flex', flexDirection:'column', flex:1}}>
          <Box sx={{flex: 1}} minHeight={"500px"}>
            {/* Stepper Header */}
            <Box mt={5} sx={{ width: '100%' }}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {Object.keys(sell_form.steps).map((step) => (
                  <Step key={step}>
                    <StepLabel>{sell_form.steps[step].label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          
            {
              /* 
                  Step 1: A form that the customer needs to fill out
              */
            }
            <FormManager
              values={form}
              visible={activeStep == 0} 
              source={sell_form.steps['basic-info']}
              onChange={(key, value)=>handleInputChange(key, value)}/>
            {
              activeStep == 1 &&
              <Box p={5}>
                <Alert severity="info">Tip: Make sure to include as many photos as you can of the inside and outside of your home. Make sure to keep your lights on and take photographs outdoors in the daytime. .</Alert>
                <MultiImageUpload></MultiImageUpload>
              </Box>
            }


            {
              activeStep == 2 &&
              <Box py={5}>
                <Box>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Do you want to list your ad?</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel value="true" control={<Radio />} label="Yes" />
                      <FormControlLabel value="false" control={<Radio />} label="Maybe Later" />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Do you plan on hosting an open house??</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel value="true" control={<Radio />} label="Yes" />
                      <FormControlLabel value="false" control={<Radio />} label="Maybe" />
                    </RadioGroup>
                  </FormControl>
                  </Box>
              </Box>
            }

          </Box>
        </Container>
        <MobileStepper
          variant="text"
          steps={steps.length}
          position="static"
          activeStep={activeStep}
          nextButton={
            // This should advance next until the end, then continue
            activeStep === steps.length-1 ?
              
              <Button
                size="large"
                onClick={handleNext}
                variant="outlined"
                color="secondary"
                disabled={!formIsValid}
              >
                Sell it!
              </Button>

              :

              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === steps.length - 1}
              >
                Next <KeyboardArrowRight />
              </Button>
            
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />

        <Box bgcolor={"white"} minHeight={50}></Box>

        



    </Box>
  );
}
export default SellNew;
