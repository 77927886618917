import { Box, Button, Container, Divider, Grid, List, ListItem, Paper, Stack, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";

import all_properties from "../../fake_data/properties.json";
import sell_progress from "../../fake_data/sell_progress.json";
import AppBanner from "../AppBanner";
import { useState } from "react";
import { ArrowBackIos, CheckCircleOutline } from "@mui/icons-material";
import PropertyProgressSidebarStep from "../PropertyProgressSidebarStep";

export default function SellProcessDetail() {

  const {property_id, step_id} = useParams();

  // This is the property
  const property  = all_properties.filter( p => p.id == property_id)?.[0];

  // This is the whole progress of the entire form, as related to this property
  const property_progress = sell_progress.filter( p => p.property_id == property_id)?.[0];

  /* This object holds the following:
  *     order,before_keys,after_keys,key,title,subtitle,status,linked_type,linked_event, wizard
  */
  const step = property_progress.steps.filter( s => s.linked_event == step_id)?.[0];
  


  const steps = [
    {
      label: 'Select campaign settings',
      description: `For each ad campaign that you create, you can control how much
                you're willing to spend on clicks and conversions, which networks
                and geographical locations you want your ads to show on, and more.`,
    },
    {
      label: 'Create an ad group',
      description:
        'An ad group contains one or more ads which target a shared set of keywords.',
    },
    {
      label: 'Create an ad',
      description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
  ];
  

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  return (
    <Box>
      <AppBanner  title="Sell Progress" subtitle={property.address1}/>
      <Grid container spacing={2} mt={2}>
        
        {/* SIDEBAR */}
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <Paper>
            <Link to={`/sell/${property_id}/process`}><Button fullWidth><ArrowBackIos/> Seller Dashboard</Button></Link>
            <Divider/>

            <List>
              {property_progress.steps.map(s => 
                <PropertyProgressSidebarStep type="sell" property={property} currentStep={step} step={s}/>
              )}
              
            </List>

          </Paper>
        </Grid>


        {/* Main Content */}
        <Grid item xs={12} sm={8} md={9} lg={10}>

          <Typography variant="h4">{step.title}</Typography>
          <Typography variant="subtitle2">{step.subtitle}</Typography>
          <Divider/>


          {/* Stepper Wizard Form */}
          <Box sx={{ maxWidth: 600 }}>

            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index === 2 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent>
                    <Typography>{step.description}</Typography>
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>All steps completed - you&apos;re finished</Typography>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button>
              </Paper>
            )}
          </Box>

        </Grid>
        

      </Grid>
    </Box>
  )
}
