import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import HouseForSale from "../../img/forsale.webp";

import {
  Badge,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  useMediaQuery,
} from "@mui/material";

import { Link, useLocation, useNavigate } from "react-router-dom";
import AppBanner from "../AppBanner";

import all_properties from '../../fake_data/properties.json';
import { Edit } from "@mui/icons-material";
import property_processes from "../../fake_data/sell_progress.json";
import { useTheme } from "@emotion/react";
function SellingPropertyOverviewCardQuickStats({property}) {
  const theme = useTheme();
  const bigEnough = useMediaQuery(theme.breakpoints.up('sm'));  

  const navigate = useNavigate();
  const linkToPropertySaleProgress = `/sell/${property.id}/process`

  return (
    <Grid container >
        <Grid item xs={12} sm={6}>
        <Typography align="center" variant="subtitle1" color="text.secondary" component="div">
            List Price
        </Typography>
        <Typography align="center" sx={{color: 'green'}} component="div" variant="h5">
            ${property.asking_price.toLocaleString('en-ca')} 
        </Typography>
        
        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography align="center" variant="subtitle1" color="text.secondary" component="div">
            Progress (2/10)
        </Typography>

        <LinearProgress variant="determinate" color="secondary" value={20} />
        <Typography align="center" variant="subtitle1" color="text.secondary" component="div">
            <Box p={1}>
                <Chip label="Waiting for offer acceptance"></Chip>
            </Box>
        </Typography>

        </Grid>
    </Grid>
  );
}
export default SellingPropertyOverviewCardQuickStats;
