import { useState } from "react";
import Card from "@mui/material/Card";
import {
  Badge,
  Box,
  Chip,
  CardContent,
  Divider,
  Typography,
  CardMedia,
  IconButton,
  Grid,
  Skeleton,
  Stack,
  useMediaQuery,
} from "@mui/material";
import HouseImage from "../../img/house-loading.png";
import { BookmarkAdd } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import BookmarkToggleButton from "../BookmarkToggleButton";

function ClassifiedsHouseCard({property, newTabOnClick=false, mdSize=3, xlSize=3}) {
  const theme = useTheme();
  const bigEnough = useMediaQuery(theme.breakpoints.up('sm'));
  
  const linkTo = `/ads/${property.id}`;
    
  return (
    
    <Grid item xs={12} sm={6} md={mdSize} xl={xlSize}>
        <Link to={linkTo} style={{textDecoration: 'none'}} target={newTabOnClick ? "_blank" : "_self"}>
            <Card sx={{position:'relative'}}>
                <CardMedia
                    component="img"
                    height="140"
                    image={property.images[0]}
                />
                <Box sx={{position:'absolute', top:10, left: 10}}>
                    <Chip sx={{textShadow:'0px 1px 3px rgba(0,0,0,0.8)', fontWeight:'bold', color:'white'}} label={`$${property.asking_price.toLocaleString('en-ca')}`}/>
                </Box>
                <CardContent>
                    <Stack spacing={1}>
                        <Typography variant={'body2'}>{property.title}</Typography>
                        <Typography variant={'body2'} color="text.secondary">{property.address1}</Typography>
                    </Stack>
                    
                    <Box textAlign={"right"}> {/* Right-side actions */}
                        <Chip sx={{fontSize:'0.4em'}} label={`${property.floors} fl`}/>
                        <Chip sx={{fontSize:'0.4em'}} label={`${property.bedrooms} bed`}/>
                        <Chip sx={{fontSize:'0.4em'}} label={`${property.bathrooms} bath`}/>
                        <Chip sx={{fontSize:'0.4em'}} label={`${property.sqft} sqft`}/>
                        <BookmarkToggleButton property={property}/>
                        
                    </Box>
                </CardContent>
                
            </Card>
        </Link>
    </Grid>
  );
}
export default ClassifiedsHouseCard;
