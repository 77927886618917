import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Badge, Icon, Link, Paper, TextField, Typography } from "@mui/material";
import { ArrowLeft, ChatBubbleOutline, ChevronLeft, KeyboardArrowLeft, MessageOutlined, MessageTwoTone, Send } from "@mui/icons-material";
import MessageBubble from "./MessageBubble";

export default function ConversationView({conversation}) {

  const [popInMessages, setPopInMessages] = useState(false);
  const [pendingMessage, setPendingMessage] = useState("");
  const scrollEnd = useRef();

  const messages = conversation!=null ? conversation.messages : [];
  console.log(messages);

  useEffect( () => {
    setPopInMessages(conversation != null)
  }, [conversation != null])

  useEffect( () => {
    scrollEnd.current.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  const handlePostMessage = () => {
    if(pendingMessage.length == 0) return;
    chatApi.postMessage(pendingMessage).then( 
      (d)=>setPendingMessage("")
    ).catch( (e) => console.error(e))
  }

  const handleMessageChange = (message) => {
    setPendingMessage(message);
  }

  return (
    <Stack style={{height:'100%'}}>
        {
          conversation != null &&
          <Box sx={{background:"rgba(255,255,255,0.5)", position:'absolute', zIndex:'1000', width:'100%', backdropFilter:'blur(20px)', top:-1}}>
            <Stack direction={"row"} sx={{alignItems:'center'}}>
              <Box component={"img"} src={conversation.property.images[0]}
                sx={{aspectRatio:1, objectFit:'cover', width: 50, height: 50, padding:1}}
              />
              <Box sx={{flex:'1'}}>
                <Typography color={'text.secondary'} sx={{fontWeight:'bold'}} variant="caption">{conversation.property.title}</Typography> - 
                <Typography color={'text.secondary'} variant="caption">{conversation.property.address1}</Typography>
              </Box>

              <Box>
                <Link target="_blank" to={`/ads/${conversation.property.id}`}>
                  <Button sx={{minWidth:'100px'}} size="small" variant="outlined">View Ad</Button>
                </Link>
              </Box>
            </Stack>
          </Box>
        }
      <Box flex={1} padding={2} sx={{overflowY:'scroll', minHeight:'1px'}}>
        <Stack gap={2}>
          {messages.map( m =>
            <MessageBubble show={popInMessages} message={m}/>
          )}
            <div ref={scrollEnd}></div>
        </Stack>
      </Box>
      { 
        conversation == null &&
        
        <Box
          style={{alignItems:'center', display:'flex', height:"calc(100% + 100px)", width: '100%', textAlign:'center'}}
        >
            <Typography color="grey.400" align="center" style={{width:'100%'}} component={'div'} variant="caption">
              <ChatBubbleOutline/>
            </Typography>
        </Box>
      }
      {
        conversation != null &&
          <Paper sx={{backgroundColor:'#e4f3f1', padding: 1}}>
            <Box sx={{display:'flex', }} gap={1}>
            
              <TextField 
                multiline={true} 
                sx={{flex:'1', background:'white'}}
                maxRows={4}
                onChange={(e) => handleMessageChange(e.target.value)}
                value={pendingMessage}
              />
            <Button onClick={() => handlePostMessage()} style={{maxHeight: '60px'}} variant={'contained'}><Send/></Button>
          </Box>
        </Paper>
      }
    </Stack>
  );
}
