import { Box, Card, CardContent, CardHeader, Container, Grid, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import steps from "../../fake_data/sell_progress.json"
import AcceptOffer from "../../img/processes/AcceptOffer.webp"
import CloseSale from "../../img/processes/CloseSale.webp"
import FinalWalkthrough from "../../img/processes/FinalWalkthrough.webp"
import GetMortgage from "../../img/processes/GetMortgage.webp"
import HomeInspect from "../../img/processes/HomeInspect.webp"
import Clean from "../../img/processes/Clean.webp"
import MakeOffer from "../../img/processes/MakeOffer.webp"
import Insurance from "../../img/processes/Insurance.webp"
import MortgageApproved from "../../img/processes/MortgageApproved.webp"
import ReceiveFunds from "../../img/processes/ReceiveFunds.webp"
import { CheckBoxOutlined, CheckCircle, CheckCircleOutline } from "@mui/icons-material";


const getImageForStep = (step_type) =>{
  switch(step_type) {
    case "accept_offer":
      return AcceptOffer;

    case "clean":
      return Clean;
    case "user_offer":
      return MakeOffer;
    case "banking_receive":
      return ReceiveFunds;
    case "mortgage":
      return GetMortgage;

    case "inspection":
      return HomeInspect;      
    case "mortgage_approved":
      return MortgageApproved;

    case "insurance":
      return Insurance;

    case "walkthrough":
      return FinalWalkthrough;

    case "receoveFunds":
      return CloseSale;

    case "closing_events":
      return CloseSale;
  }
  return null;
}

export default function ProgressStepSquare({step, type,property_id}) {

  return (
    <Grid item xs={6} sm={6} md={4} lg={3}>
      <Link to={`/${type}/${property_id}/process/${step.linked_event}/`} style={{textDecoration:"none", color:"black", fontWeight:"bold"}}>
        <Card 
          sx={{
            backgroundImage:`url(${getImageForStep(step.linked_type)})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition:'center center',   
            alignContent:'center',
            aspectRatio:1,
            position:'relative',
            opacity: .5,
          }}
        >
          <Box style={{
              color:'green', 
              background:'rgba(255,255,255,0.75)',
              aspectRatio: 1,
              width: 80,
              position: "absolute",
              top: '50%',
              left: '50%',
              marginLeft: -40,
              marginTop: -40,
              borderRadius: 80,
              paddingTop: 26
          }}><CheckCircleOutline/></Box>
          <CardContent sx={{aspectRatio:1}}>
            <Typography variant="body1" sx={{ textShadow:'0px 1px 10px 20px rgba(255,255,255,0.5)'}}>{step.title}</Typography>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  )
}
