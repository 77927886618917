import { Box, Typography, createTheme } from "@mui/material";
import { useState } from "react";
import { BrowserRouter, Routes, Route, Navigate, Link } from "react-router-dom";
import AppContainer from "./components/AppContainer";
import {themeOptions} from './theme'
import NavBar from "./components/NavBar";
import BuySellPage from "./components/BuySellPage";
import Sidebar from "./components/Sidebar";
import AdsPage from "./components/AdsPage";
import BuyWizardPage from "./components/pages/BuyWizardPage";
import UserAccount from "./components/UserAccount";
import Image404 from "./img/404.png";

import AppRoutes from "./AppRoutes";
import { ThemeProvider } from "@emotion/react";
import ChatContainer from "./universal/ChatContainer";
import { ChatProvider } from "./contexts/ChatContext";
import { AuthProvider } from "./contexts/AuthContext";
import { BookmarksProvider } from "./contexts/BookmarksContext";

function HomePage() {
  return <div className="page">🏠 Page</div>;
}

function NotFoundPage() {
  return (
    <Box
      flex={1}
      sx={{ background: "#d2ccc7", height: "100%", alignContent: "center" }}
    >
      <Box style={{ height: 0 }}>
        <Typography
          position={"relative"}
          zIndex={1000}
          variant="h1"
          sx={{
            height: "0",
            marginTop: "-200px",
            color: "white",
            boxShadow: 20,
          }}
        >
          404
        </Typography>
        <Box
          position={"relative"}
          zIndex={1}
          component="img"
          src={Image404}
          sx={{ width: 400, height: "auto" }}
        />
      </Box>
      <Link to="/">Go Back to Main</Link>
    </Box>
  );
}

export default function App() {
  const [sidebarOpen, setSidebarOpen] = useState(true); // Use screen size
  const theme = createTheme({
    ...themeOptions
  });
  
  
  return (
    
    <BrowserRouter>
      <ThemeProvider theme={theme}>

        {/* Chat Scope
            Wrap the entire application as we may need to pop open the chat from anywhere
        */}
        <AuthProvider>
          <BookmarksProvider>
            <ChatProvider>
              <AppContainer>
                <NavBar />
                <Box
                  className="MainContent"
                  sx={{ height: "calc(100% - 90px)", display: "flex" }}
                >
                  <Box sx={{ overflowY: "scroll", width: "100%" }}>
                    <AppRoutes/>
                  </Box>
                </Box>
              </AppContainer>
              <ChatContainer/>
            </ChatProvider>
          </BookmarksProvider>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}
