import * as React from "react";
import {
  Modal,
  Box,
  Typography,
  Paper,
  Stack,
  CardContent,
  Card,
  Button,
  CardMedia,
} from "@mui/material";
import AnimatedModal from "./AnimatedModal";
import { Link } from "react-router-dom";
import { EmojiPeople, House, MapsHomeWork, SavedSearch, Star } from "@mui/icons-material";
import SellerDashboardImg from "../img/seller_dashboard.webp"
import NewAdImg from "../img/make_ad.webp"
function LinkCardButton({ linkTo, image, primary, secondary }) {
  return (
        <Link style={{textDecoration:'none'}} to={linkTo}>
          <Card sx={{display:'flex'}}>
            <CardMedia component="img" sx={{ width: 100 }} image={image}/>
            <Box flex="1">
              <CardContent >
                <Typography variant="body1">{primary}</Typography>
                <Typography variant="body2" color={'text.secondary'}>{secondary}</Typography>
              </CardContent>
            </Box>
          </Card>
        </Link>
  );
}
export default LinkCardButton;
