import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stepper,
  Step,
  StepLabel,
  MobileStepper,
  Select,
  TextField,
  FormControl,
  InputLabel
} from "@mui/material";
import { Link } from "react-router-dom";
import { Label } from "@mui/icons-material";

const steps = [
  'Add Basic Information',
  'Add Photos',
  'Listing Details',
  'values'
];

function FormManager({
    visible, source, onChange, values
}) {

  const handleOnChange = (k, v) => {
    onChange(k, v);
  }


  const BuildInput = (input, value) => {
  
    const defaultProps = {id:input.name, label:input.label, value:value, readonly:input.readonly, name:input.name};

    switch(input.type) {
        case 'select':
            return <Select {...defaultProps} onChange={(e) => handleOnChange(input.name, e.target.value)}></Select>
        case 'text':
            return <TextField {...defaultProps} onChange={(e) => handleOnChange(input.name, e.target.value)}/>
        case 'number':
            return <TextField type="number"  {...defaultProps} onChange={(e) => handleOnChange(input.name, e.target.value)}/>
    }
}

  return (
    <Box py={2} className="Sell New" style={{ flex: 1, display: visible ? 'block' : 'none' }}>
        <Typography variant="h5">{source.label}</Typography>
        <Typography variant="subtitle2">{source.description}</Typography>

        {
            source.form.map( f => {
                return (
                    <Box component="fieldset">
                        <legend>{f.fieldset_name}</legend>
                        { f.inputs.map( input =>

                                <FormControl sx={{marginTop: 3}} fullWidth>
                                    { BuildInput(input, values[input.name]) }
                                </FormControl>
                            )
                        }
                    </Box>
                )
            })
        }
        
    </Box>
  );
  
}
export default FormManager;
