import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";

import all_properties from "../../fake_data/properties.json";

import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AppBanner from "../AppBanner";
import BuyingPropertyOverviewCard from "../buying/BuyingPropertyOverviewCard";
import OffersPage from "../offers/OffersPage";

function BuyWizardPage() {
  const navigate = useNavigate();
  const properties_buying = all_properties.filter( p => p.seller_user_id == 456).slice(0,1)

  return (
    <Box className="BuyWizard Page" style={{ flex: 1 }}>
      <AppBanner title="Buy A Property" />
      <Container>
        <Grid container spacing={2}>
          <Grid item xl={6} sx={{width:'100%'}}>
            <Box sx={{width:'100%'}}>
              <Box p={2}>
                <Typography component="div" variant="h5">
                  Jump Back In
                </Typography>

                <Typography variant="subtitle1" color="text.secondary" component="div">
                  Resume the buying process on your current properties
                </Typography>
              </Box>
              <Stack spacing={2}>
                {/* Loop through the properties you have for sale and resume the selling process */}
                {
                  properties_buying.map( p => 
                      <BuyingPropertyOverviewCard property={p}></BuyingPropertyOverviewCard>
                    )
                  }
              </Stack>
            </Box>
        </Grid>
        <Grid item xl={6} mt={5}>
          <OffersPage/>
        </Grid>
      </Grid> {/* End Grid */}
    
    </Container>
  </Box>
  );
}
export default BuyWizardPage;
