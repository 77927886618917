/* Flattens all inputs into a standard form
 * Why does this exist? The forms are going to get long, nested, and 
   complicated, but should stay consistent. This will flatten all inputs 
   into something more manageable for key/value setting in the states.

   eg. the results of this should be something like:
   "property-type":"" or input {name}:{prefill}

   The shape of the JSON below only dictates the order and the fieldset aspects
 */

// Todo: Add to unit tests
export const flattenProcessForm = (whole_form) => {
    // Get into the form and loop over it.
    const values = {};
    const stepKeys =  Object.keys(whole_form.steps).map( stepKey => {
        // Loop over each of the fieldset per key
        const fieldset = whole_form.steps[stepKey].form;
        // Drill down into the fieldset
        fieldset.map( set => {

            // Now drill down into the set's inputs and loop them
            set.inputs.map( (input) => {
                values[input.name] = input.prefill
            })
        })

    })
    

    return values;
}

export const sell_form = {
    process_name: "Sell A Property",
    description: "Follow the steps below to sell your property. If you need help, go fuck yourself.",

    steps: {
        "basic-info": {
            label:"Add Basic Information",  
            description:"Add basic information to your property to help identify it.",
            form: [
                {
                    fieldset_name:"Property Location",
                    fieldset_description:"Details about the location of your property",
                    inputs: [
                        {
                            name:"property-type",
                            readonly:false,
                            placeholder:"",
                            label:"Property Type",
                            prefill:"",
                            type:"select",
                            options:[
                                {value:"condo", label:"Condo"},
                                {value:"townhouse", label:"Townhouse"},
                                {value:"house", label:"House"},
                                {value:"commercial-unit", label:"Commercial Unit"},
                                {value:"commercial-building", label:"Commercial Building"}
                            ]
                        },
                        {
                            name:"street_address",
                            readonly:false,
                            placeholder:"1234 Neat St.",
                            label:"Street Address",
                            prefill:"",
                            type:"text"
                        },
                        {
                            name:"postal_code",
                            readonly:false,
                            placeholder:"",
                            label:"Postal Code",
                            prefill:"",
                            type:"text"
                        },
                        {
                            name:"country",
                            readonly:true,
                            placeholder:"eg. Canada",
                            label:"Country",
                            prefill:"Canada",
                            type:"text"
                        }                    
                    ]
                },
                {
                    fieldset_name:"Listing",
                    fieldset_description:"Important details about your listing",
                    inputs: [
                        {
                            "name":"square_feet",
                            "readonly":false,
                            "placeholder":"eg. 1100",
                            "label":"Square Feet/Square Metres",
                            "prefill":"1000",
                            "type":"number",
                            "number_min": 100,
                            "number_max": 100000,
                        },
                        {
                            "name":"asking_price",
                            "readonly":false,
                            "placeholder":"eg. Canada",
                            "label":"Country",
                            "prefill":"100000",
                            "type":"number",
                            "number_min": 10000,
                            "number_max": 100000000,
                        },
                        {
                            "name":"monthly_fees",
                            "readonly":false,
                            "placeholder":"eg. Canada",
                            "label":"Monthly/Annual Fees",
                            "prefill":"Canada",
                            "type":"number",
                            "number_min": 10000,
                            "number_max": 100000000,
                        }
                    ]
                }
            ],
        },
        "upload-images": {
            label:"Upload Images",  
            description:"Add some images",
            form:[]
        },
        "listing-details": {
            label:"Listing Details",  
            description:"Confirm your listing and decide whether or not to make it a public ad.",
            form:[]
        }     
    } // basic-info
}