
import { useState } from "react";
import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";
import { styled } from "@mui/system";
import Slide from '@mui/material/Slide';

import Box from "@mui/material/Box";

import { Avatar, Badge, Link, Paper, Typography } from "@mui/material";

import { ArrowLeft } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

export default function MessageBox({text, sender, show }) {
    const theme = useTheme();
    const primaryColor = "#ffffff";
    const secondaryColor = theme.palette.primary.main;

    const isClient= sender == "buyer";
    
    const clientTheme = {
        backgroundColor:secondaryColor,
        color: theme.palette.secondary.contrastText
    }
    const staffTheme = {
        backgroundColor:primaryColor,
        color: theme.palette.primary.main
    }
    const backdropStyle = isClient ? clientTheme : staffTheme;

    const radius = 20;

  return (
    <Slide direction={sender == "buyer" ? "up":"down"} in={show} mountOnEnter unmountOnExit>
        <Paper sx={{marginTop:isClient?0:2, flex:1, marginRight: '0', padding:3, borderRadius:`${isClient ? radius : 3}px ${isClient ?  3: radius}px ${radius}px ${radius}px`, ...backdropStyle }}>
            <Typography fontSize={'0.7em'}>{text}</Typography>
        </Paper>
      </Slide>
  );
}
