
import { useState } from "react";
import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";
import { styled } from "@mui/system";

import Box from "@mui/material/Box";

import { Avatar, Badge, Link, Paper, Typography } from "@mui/material";

import { ArrowLeft } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import MessageBox from "./MessageBox";

export default function MessageBubble({message, show}) {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const secondaryColor = theme.palette.secondary.main;
  console.log("MessageBubble", message)
  if(message.sender == "seller") {
    return (
        <Box display={'flex'} gap={1}>
          <Avatar sx={{background:secondaryColor}} alt="Store" src="/static/images/avatar/1.jpg" />
          <MessageBox show={show} text={message.text} sender={message.sender}/>
        </Box>  
      );
  }
  if(message.sender == "buyer") {
    return (
        <Box display={'flex'}>
          <MessageBox show={show} text={message.text} sender={message.sender}/>
        </Box>  
    );
  }
  
}
