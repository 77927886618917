import { Box } from "@mui/material";
import * as React from "react";

function AppContainer({ children }) {
  return (
    <Box
      style={{
        background: "#EFE6E2",
        padding: 2,
        textAlign: "center",
      }}
      className="AppContainer"
    >
      {children}
    </Box>
  );
}
export default AppContainer;
