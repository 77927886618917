import React, { createContext, useContext, useMemo, useState } from 'react';
import {fakeResponse} from "../helpers/fakeApi";
import fake_users from "../fake_data/users.json";

const AuthContext = createContext();
    const AuthProvider = ({ children }) => {
        const [currentUser, setCurrentUser] = useState(null);
        const userValue = useMemo(() => ({ currentUser, setCurrentUser }), [currentUser]);

        const logOut = () => {
            setCurrentUser(null);
            return fakeResponse({
                'message':'Logged Out'
            });
            location.reload()
        }

        const logIn = (user_id) => {
            const testUser = fake_users.filter(u => u.id == user_id)?.[0]
            const result = testUser ? testUser : {};
            return fakeResponse({
                'message':'Welcome back!'
            }).then( d => setCurrentUser(result));
        }

        const fetchProfile = () => {
            if(currentUser) return fakeResponse({
                profile: {
                    ...currentUser
                }
            });

            return fakeResponse(null);
        }

        return (
            <AuthContext.Provider value={{userValue, logIn, logOut, fetchProfile}}>
                {children}
            </AuthContext.Provider>
        );
    };

// Custom hook to use the API context
const useAuth = () => {
    return useContext(AuthContext);
};

export { AuthProvider, useAuth };
