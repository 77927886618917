import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import HouseForSale from "../../img/forsale.webp";

import {
  Badge,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AppBanner from "../AppBanner";

import all_properties from '../../fake_data/properties.json';
import { Edit } from "@mui/icons-material";
import property_processes from "../../fake_data/sell_progress.json";
import { useTheme } from "@emotion/react";
function BuyingPropertyOverviewCard({property}) {
  const theme = useTheme();
  const smallSized = useMediaQuery(theme.breakpoints.down('md'));
  const superSized = useMediaQuery(theme.breakpoints.up('xl'));

  const smallEnough = smallSized || superSized;

  const navigate = useNavigate();
  const linkToPropertySaleProgress = `/buy/${property.id}/process`

  const ActionButtons = () => {
    return (
      <Box mt={smallEnough ? 2 : 0}>
        <Stack spacing={1} direction={ "row"} justifyContent={smallEnough ? 'center' : 'right'}>

          <Link to={linkToPropertySaleProgress}>
              <Button variant="outlined">View Progress</Button>
          </Link>
          
          <Badge badgeContent="1" color="secondary">
              <Button variant="outlined">Manage Your Offer</Button>
          </Badge>
      </Stack>
    </Box>
    )
  }

  return (
        <Card sx={{ display: smallEnough ? 'block' : 'flex' }}>
            <CardMedia
                component="img"
                sx={{ width: smallEnough ? '100%' : 200, height: smallEnough ? '200px' : 'auto' }}
                image={property.images[0]}
                alt="For Sale Sign on Lawn"
                />
            <Box sx={{ flex: '1 0 auto', textAlign: 'left' }}>
              <CardHeader 
                title={property.address1} 
                subheader={"Listed July 2, 2024"}
                action={

                    smallEnough ? 
                    <></> // Show nothing
                    :
                    ActionButtons() // Show nothing
                }          
                ></CardHeader>
                <CardContent>
                <Grid container >
                    <Grid item xs={6} sm={6} md={4}>
                        <Typography align="center" variant="subtitle1" color="text.secondary" component="div">
                            List Price
                        </Typography>
                        <Typography align="center" sx={{color: 'green'}} component="div" variant="h5">
                            ${property.asking_price.toLocaleString('en-ca')} 
                        </Typography>
                    
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <Typography align="center" variant="subtitle1" color="text.secondary" component="div">
                            Your Last Offer
                        </Typography>
                        <Typography align="center" sx={{color: 'green'}} component="div" variant="h5">
                            ${(property.asking_price*(0.95)).toLocaleString('en-ca')} 
                        </Typography>
                    
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                    <Typography align="center" variant="subtitle1" color="text.secondary" component="div">
                        Progress (2/10)
                    </Typography>

                    <LinearProgress variant="determinate" color="secondary" value={20} />
                    <Typography align="center" variant="subtitle1" color="text.secondary" component="div">
                        <Box p={1}>
                            <Chip label="Waiting for offer acceptance"></Chip>
                        </Box>
                    </Typography>

                    </Grid>
                </Grid>
                {
                  smallEnough &&
                  ActionButtons()
                }
                </CardContent>
            </Box>
            </Card>
  );
}
export default BuyingPropertyOverviewCard;
