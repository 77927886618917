import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  Paper,
  Stack,
  Select,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import AppBanner from "./AppBanner";
import SearchStack from "./ads/SearchStack";
import { Bookmark, BookmarkAdd, HeartBrokenOutlined, Map, SortOutlined } from "@mui/icons-material";
import HouseLoadingSkeleton from "./skeletons/ClassifiedsHouseCard";

import all_properties from "../fake_data/properties.json";
import ClassifiedsHouseCard from "./skeletons/ClassifiedsHouseCard";
import { useTheme } from "@emotion/react";

function AdsPage() {
  const theme = useTheme();
  const bigEnough = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Container className="Stats Page" style={{ flex: 1 }}>

      {/* Search */}
      <Stack spacing={2}>
        <Container>
          <Box py={2}>
            <Paper m={2} p={2}>
              <SearchStack/>
            </Paper>
          </Box>
        </Container>


        {/* Results Cover */}
        <Container sx={{textAlign:'left'}}>
          <Stack direction={"row"}>
            <Box py={2}>
              <FormControl size="small">
                <InputLabel id="ads-sort">Sort By</InputLabel>
                <Select
                  labelId="ads-sort"
                  id="demo-simple-select"
                  value={"price asc"}
                  label="Sort By"
                >
                  <MenuItem value={"price asc"}>Price</MenuItem>
                  <MenuItem value={"distance asc"}>Distance From Search</MenuItem>
                  <MenuItem value={"time_on_market asc"}>Time on Market</MenuItem>
                </Select>
              </FormControl>
              <IconButton>
                <SortOutlined/>
              </IconButton>
            </Box>
            <Box flex={1}></Box>
            <Box py={2}>
              <Button variant="contained" color="secondary"><Map/> Show Map View</Button>
            </Box>
          </Stack>
        </Container>


        {/* Ad Grid */}
        <Container>
          <Grid container spacing={2}>
            {
              all_properties.map(p => 
                <ClassifiedsHouseCard property={p}/>
              )
            }
          </Grid>
        </Container>

        <Container py={2} my={2}>
          <Stack my={5} spacing={3}>
            <Pagination  count={10} color="primary" sx={{justifyContent:'center', display:'flex'}}/>
            <Box>
              <Button variant="outlined">
                Back To Top
              </Button>
            </Box>
          </Stack>
        </Container>
      </Stack>
      

    </Container>
  );
}
export default AdsPage;
