import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { ArrowLeft } from "@mui/icons-material";

function AppBanner({Action=null, backlink=null, title = "", subtitle=null, style={} }) {
  return (
    <Box p={5} style={{ width: "100%", background: "#0B1E3A88", ...style }}>
      {
        backlink &&
        <Box>
          <Link to={backlink}>
            <IconButton>
              <ArrowLeft/>
            </IconButton>
          </Link>
        </Box>
      }
      <Typography
        variant={"h2"}
        sx={{
          color: "white",
          textShadow: "0px 3px 10px rgba(0,0,0,0.2)",
          textAlign: { xs: "left", sm: "center" },
          typography: { sm: "h3", xs: "h4" },
        }}
      >
        {title}
      </Typography>
      <Typography
        variant={"subtitle2"}
        sx={{
          color: "white",
          textShadow: "0px 3px 10px rgba(0,0,0,0.2)",
          textAlign: { xs: "left", sm: "center" },
          typography: { sm: "subtitle1", xs: "subtitle2" },
        }}
      >
        {subtitle}
      </Typography>
      {
        Action!=null &&
        <Box>
          <Action/>
        </Box>
      }
    </Box>
  );
}
export default AppBanner;
