import { Button, FormControl, IconButton, InputBase, InputLabel, MenuItem, Select, Stack, alpha, styled } from "@mui/material";
import Box from "@mui/material/Box";
import SearchIcon from '@mui/icons-material/Search';
import { useState } from "react";
import { Filter, Filter1Outlined, FilterList } from "@mui/icons-material";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));


const defaultSearchParams = {
    square_footage: null,
    max_price: null,
    postal_code: "v8p5l1",
}
  
function SearchStack() {

    const [searchParams, setSearchParams] = useState(defaultSearchParams);

    return(
        
        <Stack direction="row">

            {/* Searchbox */}
            {/* <Box p={1}>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </Search>
            </Box> */}

            {/* Square Footage */}
            <Box sx={{minWidth: 180}} p={1}>
                <FormControl size="small" fullWidth>
                    <InputLabel id="search_square_footage">Square Footage</InputLabel>
                    <Select
                    
                        labelId="search_square_footage"
                        id="demo-simple-select"
                        value={searchParams.square_footage}
                        label="Square Footage"
                        onChange={(e)=>setSearchParams({...searchParams, square_footage:  e.target.value})}
                    >
                        <MenuItem value={10}>1000</MenuItem>
                        <MenuItem value={20}>1000-1300</MenuItem>
                        <MenuItem value={30}>1300-2000</MenuItem>
                        <MenuItem value={30}>2000-3000</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Box sx={{minWidth: 150}} p={1}>
                <FormControl size="small" fullWidth>
                    <InputLabel id="square_max_price">Max Price</InputLabel>
                    <Select
                        labelId="square_max_price"
                        id="demo-simple-select"
                        value={searchParams.max_price}
                        label="Max Price"
                        onChange={(e)=>setSearchParams({...searchParams, max_price:  e.target.value})}
                    >
                        <MenuItem value={10}>1000</MenuItem>
                        <MenuItem value={20}>1000-1300</MenuItem>
                        <MenuItem value={30}>1300-2000</MenuItem>
                        <MenuItem value={30}>2000-3000</MenuItem>
                    </Select>
                </FormControl>
            </Box>


            {/* Spacer */}
            <Box flex={1}/>

            <Box p={1}>
                <IconButton variant="contained">
                    <FilterList/>
                </IconButton>
            </Box>

            <Box p={1}>
                <Button variant="contained" color="secondary">
                    <SearchIcon/> Set Search
                </Button>
            </Box>

        </Stack>
  );
}
export default SearchStack;
