import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import logo from "../img/logo-hero.png";
import { Link } from "react-router-dom";
import { Badge, ListItem, Stack } from "@mui/material";
import { CheckCircleOutline, Circle, CircleOutlined, Pending } from "@mui/icons-material";
const settings = ["Profile", "Account & Privacy"];

function PropertyProgressSidebarStep({type, currentStep, step, property}) {

  return (
    <Link
        to={`/${type}/${property.id}/process/${step.linked_event}`}
        style={{textDecoration:'none', color:'black'}}
    >
        <ListItem>
            <Stack direction="row">
            <Box>
                {
                    step.status == "incomplete" &&
                    <CircleOutlined sx={{color:'grey'}}/>
                }

                {
                    step.status == "pending" &&
                    <Pending sx={{color:'skyblue'}}/>
                }

                {
                    step.status == "complete" &&
                    <CheckCircleOutline sx={{color:'green'}}/>
                }

            </Box>
            <Box>
                <Typography variant="body1">{step.title}</Typography>
                <Typography variant="caption">{step.status}</Typography>
            </Box>
            </Stack>
        </ListItem>
    </Link>
  );
}
export default PropertyProgressSidebarStep;
