import { Badge, Box, Button, Card, CardContent, CardMedia, Container, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import processes from "../../fake_data/sell_progress.json"
import all_properties from "../../fake_data/properties.json"
import ProgressStep from "./ProgressStep";
import { useState } from "react";
import ProgressStepSquare from "./ProgressStep";
import { ChatBubble, CurrencyExchange, Edit } from "@mui/icons-material";

export default function SellProcessMaster() {

  const {property_id} = useParams();
  const [currentProcess, setCurrentProcess] = useState(processes[0]);
  const currentProperty = all_properties.filter(p=>p.id == property_id)?.[0];
  console.log(currentProperty)

  return (
    <Box
    className="BuySellPage Page"
    style={{
      height: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
    }}
    >
        <Container>
          <Stack direction="row" py={2} spacing={2}>

            <Card sx={{maxWidth:200}}>
              <CardMedia component="img"
                height="140" image={currentProperty.images[0]}/>
                <CardContent sx={{textAlign:'left'}}>
                  <Box mb={2}>
                    <Button variant="outlined" fullWidth><Edit/> Edit Listing</Button>
                  </Box>
                  <Typography variant={"body1"} textAlign={"left"}>{currentProperty.address1}</Typography>
                  <Typography variant={"body2"} color="text.secondary" textAlign={"left"}>Listed Price:</Typography>
                  <Typography variant={"body1"} textAlign={"left"} color={"green"}>${currentProperty.asking_price.toLocaleString('en-ca')}</Typography>
                  <Divider/>
                  <Typography variant={"caption"} textAlign={"left"}>{currentProperty.description}</Typography>
                  <Box py={1}>
                    <Badge sx={{width:'100%'}} badgeContent={2} color="secondary">
                      <Button variant="outlined" fullWidth><CurrencyExchange/> View Offers</Button>
                    </Badge>
                  </Box>
                  <Box mb={2}>
                    <Button variant="outlined" fullWidth><ChatBubble/> Chat with Buyers</Button>
                  </Box>
                </CardContent>
            </Card>
            <Box flex={1}>
              <Typography variant={"h5"} px={4} textAlign={"left"}>Selling Property: {currentProperty.address1}</Typography>
              <Grid p={5} container spacing={2}>
                  {currentProcess.steps.map(s => 
                    <ProgressStepSquare type="sell" property_id={currentProcess.property_id} step={s}/>
                  )}
              </Grid>
            </Box>
          </Stack>
          </Container>
          
      </Box>
  )
}
